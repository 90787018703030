<template>
  <Paper v-if="paperId" :share-paper-id="paperId" />
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "Share",
  components: {
    Paper: () => import("./Paper")
  },
  data() {
    return {
      paperId: null
    };
  },
  async created() {
    let url,
      params = {};
    switch (this.$route.name) {
      case "Share":
        url = "/dis/p/sget";
        params.visitKey = this.$route.params.info;
        break;
      case "ShareEmail":
        url = "/dis/p/emailsget";
        params.visitKey = this.$route.query.visitKey;
        params.email = this.$route.query.email;
        break;
    }

    let { info } = await this.$axios.get(url, { params });
    if (info) {
      this.paperId = info.paperId;
      if (info.visiterInfo || (info.userInfo && info.userInfo.id)) {
        if (info.operateMode !== 1) {
          this.$message({
            message: "游客状态仅能查看，登录后可获取更多权限",
            type: "warning",
            duration: 0,
            showClose: true
          });
        }
        info.operateMode = 1;
        info.visiterInfo.roleauth = "visiter";
        this.exportCanvasData(info.visiterInfo || info.userInfo);
      }
      this.$UpdataVuexState({
        key: "paperOperateMode",
        data: info.operateMode - 0
      });
    } else {
      this.$router.replace({ path: "/error_view/404" });
    }
  },
  methods: {
    exportCanvasData(info) {
      let canvas = document.createElement("canvas");
      canvas.width = "100";
      canvas.height = "100";
      let ctx = canvas.getContext("2d");
      ctx.rect(0, 0, 100, 100);
      ctx.fillStyle = info.headImg;
      ctx.fillRect(0, 0, 100, 100);
      let img = document.createElement("img");
      img.src = "/img/guest_bee_k.png";
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 100, 100);
        info.headImg = canvas.toDataURL();
        this.$SaveXppUserInfo(info);
      };
    },
    ...mapMutations(["$UpdataVuexState", "$SaveXppUserInfo"])
  }
};
</script>
